import { css } from 'emotion';
import { themeVars } from './styles';

const sizes = {
  small: themeVars.root.small,
  medium: themeVars.root.medium,
  large: themeVars.root.large,
  xlarge: themeVars.root.xlarge,
  xxlarge: themeVars.root.xxlarge,
};

// Exports
// -----
export const breakup = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export const breakdown = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});
