import { injectGlobal } from 'emotion';
import { darken } from 'polished';

export const themeVars = {
  root: {
    // Colours
    primary: '#802246',
    secondary: '#4B5D6D',
    secondaryDark: `${darken(0.2, '#4B5D6D', 0.6)}`,
    tertiary: '#9FAABC',
    dark: '#000000',
    darkAlt: '#171A27',
    light: '#FFFFFF',

    // Fonts
    headerFontFamily: '"neuzeit-grotesk", Helvetica, Arial, sans-serif;',
    bodyFontFamily: '"neuzeit-grotesk", Helvetica, Arial sans-serif;',

    // Breakpoints
    small: '0',
    medium: '540px',
    large: '960px',
    xlarge: '1140px',
    xxlarge: '1600px',

    // Padding
    padTopSm: '3rem',
    padTopMd: '4rem',
    padTopLg: '6rem',

    padBottomSm: '3rem',
    padBottomMd: '4rem',
    padBottomLg: '6rem',
  },
};

export const globalStyles = injectGlobal`

  /* @import url("https://use.typekit.net/jei0pkb.css"); */
  /* Nuezuit Grotesk: 300, 400, 700 */

  /* @import url("https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i"); */
  /* PT Sans: 400, 400i, 700, 700i */

  html {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  html, body {
    font-size: 62.5%;
    overflow-x: hidden;
  }

  body {
    font-family: ${themeVars.root.bodyFontFamily};
    font-size: 1.8rem;
    margin: 0;
  }

  img {
    max-width: 100%;
    transition: all 1s ease-in-out !important;
  }

  a {
    text-decoration: none;
  }

  p, li, td {
    margin-top: 0;
    font-size: 1.6rem;
    line-height: 1.4;

    @media (min-width: ${themeVars.root.large}){ font-size: 1.8rem; }

    a {
      color: ${themeVars.root.primary};

      &:hover {
        text-decoration: underline;
      }
    }
  }

  section, div { box-sizing: border-box; }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    font-family: ${themeVars.root.headerFontFamily};
    font-weight: 300;
    line-height: 1.2;

    span {
      font-weight: 400;
    }
  }

  h1 {
    font-size: 3.2rem;
    @media (min-width: ${themeVars.root.medium}){ font-size: 3rem; }
    @media (min-width: ${themeVars.root.xlarge}){ font-size: 4rem; }
    @media (min-width: ${themeVars.root.xxlarge}){ font-size: 5rem; }

  }

  h2 {
    font-size: 2.6rem;
    @media (min-width: ${themeVars.root.medium}){ font-size: 3.6rem; }
    @media (min-width: ${themeVars.root.large}){ font-size: 4rem; }
  }

  h3 {
    font-size: 2.4rem;
    @media (min-width: ${themeVars.root.medium}){ font-size: 3rem; }
    @media (min-width: ${themeVars.root.large}){ font-size: 3.4rem; }
  }

  h4 {
    font-size: 2rem;
    @media (min-width: ${themeVars.root.medium}){ font-size: 2.4rem; }
    @media (min-width: ${themeVars.root.large}){ font-size: 3rem; }
  }

  h5 {
    font-size: 2rem;
    @media (min-width: ${themeVars.root.medium}){ font-size: 2.2rem; }
    @media (min-width: ${themeVars.root.large}){ font-size: 2.6rem; }
  }

  h6 {
    font-size: 1.8rem;
    @media (min-width: ${themeVars.root.medium}){ font-size: 2rem; }
    @media (min-width: ${themeVars.root.large}){ font-size: 2.4rem; }
  }

  input {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  /* Padding */
  .pad-top {
    padding-top: ${themeVars.root.padTopSm};
    @media (min-width: ${themeVars.root.medium}){ padding-top: ${themeVars.root.padTopMd}; }
    @media (min-width: ${themeVars.root.large}){ padding-top: ${themeVars.root.padTopLg}; }
  }

  .pad-bottom {
    padding-bottom: ${themeVars.root.padBottomSm};
    @media (min-width: ${themeVars.root.medium}){ padding-bottom: ${themeVars.root.padBottomMd}; }
    @media (min-width: ${themeVars.root.large}){ padding-bottom: ${themeVars.root.padBottomLg}; }
  }
`;

export default themeVars;
