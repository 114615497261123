import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import shortid from 'shortid';
import { css } from 'emotion';
import { themeVars } from '../../config/styles';

const cssNoLink = css`
  color: ${themeVars.root.light};
`;

const NavMenuListItem = ({ label, url, active, sublinks }) => {
  return (
    <>
      {active !== 'inactive' && (
        <li key={shortid.generate()}>
          {!sublinks ? (
            <Link to={`/${url}/`}>{label}</Link>
          ) : (
            <span className={cssNoLink}>{label}</span>
          )}
          {sublinks && (
            <ul>
              {sublinks.map((item) => {
                return (
                  <li key={shortid.generate()}>
                    <Link to={`/${item.object_slug}/`}>{item.title}</Link>
                  </li>
                );
              })}
            </ul>
          )}
        </li>
      )}
    </>
  );
};

NavMenuListItem.defaultProps = {
  sublinks: null,
  active: null,
};

NavMenuListItem.propTypes = {
  /** The label of the NavMenuListItem */
  label: PropTypes.string.isRequired,

  /** Submenu activity */
  active: PropTypes.string,

  /** The URL of the NavMenuListItem */
  url: PropTypes.string.isRequired,

  /** Sub navigation */
  sublinks: PropTypes.arrayOf(PropTypes.shape()),
};

export default NavMenuListItem;
