import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Link } from 'gatsby';
import { isEmpty } from 'lodash';

// Vars
import { themeVars } from '../../config/styles';

// import Button from '../Common/ButtonDark';

const MobileMenu = ({ isMenuOpen, handleMenuClose, menuItems }) => {
  const cssMenu = css`
    width: 80vw;
    padding: 2rem;
  `;

  const cssClose = css`
    position: relative;
    z-index: 10;

    float: right;
    border: 0;
    background: transparent;
    outline: none;
    cursor: pointer;

    font-size: 1.6rem;
    color: ${themeVars.root.secondary};
  `;

  const cssNav = css`
    padding-top: 6rem;
    padding-left: 2rem;

    ul {
      padding-inline-start: 0;
      list-style: none;
      margin: 0;

      li {
        display: block;
        margin-bottom: 2rem;

        span {
          transition: all 0.3s ease-in-out;
          font-size: 2rem;
          font-weight: 500;
          color: ${themeVars.root.primary};
        }

        a {
          transition: all 0.3s ease-in-out;
          font-size: 2rem;
          font-weight: 500;
          color: ${themeVars.root.primary};

          &:hover {
            color: ${themeVars.root.secondary};
          }
        }
      }

      ul {
        &.sub {
          margin-top: 1rem;
          margin-left: 3rem;
          margin-bottom: 2rem;

          li {
            font-size: 1.4rem;

            a {
              color: ${themeVars.root.darkAlt};
            }
          }
        }
      }
    }
  `;

  return (
    <Drawer anchor="right" open={isMenuOpen} onClose={handleMenuClose} variant="temporary">
      <div className={cssMenu}>
        <button className={cssClose} onClick={handleMenuClose}>
          Close
        </button>

        <nav className={cssNav}>
          <ul>
            {menuItems.map((item) => {
              // Show a custom link if the menu item has button class
              if (item.classes === 'button') {
                return (
                  <li key={shortid.generate()}>
                    <a aria-label={item.title} href={item.url}>
                      {item.title}
                    </a>
                  </li>
                );
              }

              return (
                <li key={shortid.generate()}>
                  {isEmpty(item.wordpress_children) ? (
                    <Link to={`/${item.object_slug}/`}>{item.title}</Link>
                  ) : (
                    <React.Fragment>
                      <span>{item.title}</span>
                      {item.wordpress_children && (
                        <ul className="sub">
                          {item.wordpress_children.map((subitem) => {
                            return (
                              <li key={shortid.generate()}>
                                <Link to={`/${subitem.object_slug}/`}>{subitem.title}</Link>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </React.Fragment>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </Drawer>
  );
};

MobileMenu.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default MobileMenu;
