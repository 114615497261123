import React, { useState } from 'react';
import { Container, Col, Row, Visible, Hidden } from 'react-grid-system';
import { css } from 'emotion';
import { StaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { breakup } from '../../config/breakpoints';

import Logo from './Logo';
import NavMenu from './NavMenu';
import NavMenuList from './NavMenuList';
import NavMenuListItem from './NavMenuListItem';

import cssTriangleImage from '../../assets/images/triangle.svg';
import MobileMenu from './MobileMenu';
import Button from '../Common/ButtonLight';

// Component
// ----
const Header = ({ logo, isHome }) => {
  // Hooks
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function handleMenuOpen() {
    setIsMenuOpen(true);
  }

  function handleMenuClose() {
    setIsMenuOpen(false);
  }

  const cssTriangle = css`
    span {
      &.triangle {
        display: none;

        ${breakup.large`
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: auto;
          pointer-events: none;

          img {
            float: left;
            max-height: 24rem;
          }
        `}

        ${breakup.xlarge`
          img {
            max-height: 28rem;
          } 
        `}

        ${breakup.xxlarge`
          img {
            max-height: 30rem;
          } 
        `}
      }
    }
  `;

  const header = css`
    position: absolute;
    width: 100%;
    z-index: 1000;
    top: 0;
    padding: 2rem 0;

    ${breakup.large`padding: 4rem 0;`}
    ${breakup.xlarge`padding: 6rem 0;`}

    ${!isHome && cssTriangle};
  `;

  const StyledButton = css`
    display: flex;
    margin-left: auto;
    border: 0;
    background: transparent;
    outline: none;
    cursor: pointer;

    span {
      font-size: 1.6rem;
      color: #ffffff;
    }
  `;

  return (
    <StaticQuery
      query={graphql`
        query {
          wordpressWpApiMenusMenusItems(name: { eq: "Primary" }) {
            items {
              title
              object_slug
              classes
              url
              wordpress_children {
                title
                object_slug
                url
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <>
            <MobileMenu
              isMenuOpen={isMenuOpen}
              handleMenuClose={handleMenuClose}
              menuItems={data.wordpressWpApiMenusMenusItems.items}
            />
            <section className={header}>
              {!isHome && (
                <span className="triangle">
                  <img src={cssTriangleImage} alt="" />
                </span>
              )}
              <Container fluid>
                <Row>
                  <Col xs={4} md={3} push={{ md: 1 }}>
                    <Link to="/" aria-label="Homepage">
                      <Logo image={logo} />
                    </Link>
                  </Col>
                  <Col xs={8} md={7} push={{ md: 1 }}>
                    <Hidden xl>
                      <button className={StyledButton} onClick={handleMenuOpen} type="text">
                        <span>Menu</span>
                      </button>
                    </Hidden>
                    <Visible xl>
                      <NavMenu alignRight hasButton>
                        <NavMenuList>
                          {data.wordpressWpApiMenusMenusItems.items.map((item) => {
                            // Show a custom link and white button if the menu item has button class
                            if (item.classes === 'button') {
                              return (
                                <li key={shortid.generate()}>
                                  <Button href={item.url}>{item.title}</Button>
                                </li>
                              );
                            }

                            return (
                              <NavMenuListItem
                                label={item.title}
                                key={shortid.generate()}
                                url={item.object_slug}
                                button={false}
                                active={item.classes}
                                sublinks={item.wordpress_children}
                              />
                            );
                          })}
                        </NavMenuList>
                      </NavMenu>
                    </Visible>
                  </Col>
                </Row>
              </Container>
            </section>
          </>
        );
      }}
    />
  );
};

Header.defaultProps = {
  isHome: false,
};

Header.propTypes = {
  /** Header isHome */
  isHome: PropTypes.bool,

  /** Logo */
  logo: PropTypes.string.isRequired,
};

export default Header;
