import React from 'react';
import { css } from 'emotion';
import PropTypes from 'prop-types';

import { themeVars } from '../../config/styles';
import { breakup } from '../../config/breakpoints';

// Component
// -----
const NavMenu = ({ alignRight, children }) => {
  const cssMenu = css`
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: ${alignRight ? 'flex-end' : 'flex-start'};
    }

    li {
      position: relative;
      margin-left: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      ${breakup.large`margin-left: 3rem;`}
      ${breakup.xlarge`margin-left: 4rem;`}

      a {
        display: block;
        color: ${themeVars.root.light};
        font-size: 1.8rem;

        &:hover {
          text-decoration: underline;
        }
      }

      ul {
        transition: all 0.3s ease-in-out;
        display: none;
        position: absolute;
        z-index: 5;
        overflow: hidden;
        left: 0;
        top: 5rem;
        width: 25rem !important;
        height: auto;
        max-height: 0;
        background: ${themeVars.root.darkAlt};

        &:before {
          transition: all 0.3s ease-in-out;
          content: '';
          position: absolute;
          top: 0;
          left: 1rem;

          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;

          border-bottom: 5px solid ${themeVars.root.darkAlt};
        }

        li {
          display: block;
          width: 100%;
          max-width: 25rem;
          margin: 0;

          a {
            padding: 1rem 2rem;
          }
        }
      }

      &:hover {
        ul {
          display: block;
          overflow: initial;
          height: auto;
          max-height: 999999rem;

          &:before {
            top: -5px;
            transition-delay: 0.2s;
          }
        }
      }
    }
  `;

  return (
    <nav className={cssMenu}>
      <ul>{children}</ul>
    </nav>
  );
};

// Props
// -----
NavMenu.defaultProps = {
  alignRight: false,
};

NavMenu.propTypes = {
  /**  List of items using the <ItemList> component */
  children: PropTypes.element.isRequired,

  /** Navigation List Alignment */
  alignRight: PropTypes.bool,
};

export default NavMenu;
