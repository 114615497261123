import React from 'react';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import { themeVars } from '../../config/styles';

const Title = ({ children, color, size }) => {
  const cssTitle = css`
    margin-bottom: 15px;
    color: ${color};

    strong {
      font-weight: 700;
    }

    span {
      text-decoration: underline;
    }

    a {
      color: ${themeVars.root.primary};
    }
  `;

  const Heading = size > 6 ? 'h6' : `h${size}`;

  return (
    <Heading className={cssTitle}>
      <div dangerouslySetInnerHTML={{ __html: children }} />
    </Heading>
  );
};

Title.defaultProps = {
  color: themeVars.root.dark,
  size: 32,
};

Title.propTypes = {
  /** The title (h2) */
  children: PropTypes.node.isRequired,

  /** Heading type */
  size: PropTypes.number,

  /** Color of the Title */
  color: PropTypes.string,
};

export default Title;
