import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ image }) => {
  return <img src={image} alt="" />;
};

Logo.propTypes = {
  /** The label of the Logo */
  image: PropTypes.string.isRequired,
};

export default Logo;
