import React from 'react';
import PropTypes from 'prop-types';

const NavMenuList = ({ children }) => <React.Fragment>{children}</React.Fragment>;

NavMenuList.propTypes = {
  /** A list of content <Item> components */
  children: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default NavMenuList;
